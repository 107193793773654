<template>
  <div>
    <b-modal id="openTrxDetail" title="Transaction" size="xl" @hidden="closeModal">
      <b-card>
        <div class="text-right" style="display: inline-block">
          <span
            class="btn btn-outline-primary btn-xs p-1"
            @click="add_service_modal = true"
            >Create Services</span
          >
          <span
            class="btn btn-outline-primary btn-xs ml-2 p-1"
            @click="spModal = true"
            >Create Service Provider</span
          >
          <span
            class="btn btn-outline-primary btn-xs ml-2 p-1"
            @click="smModal = true"
            >Service Mapper</span
          >
        </div>
        <b-row>
          <b-col>
            <label class="">Select Service <span style="color: red">*</span> </label>
            <multiselect
              class="border-0"
              :options="serviceList"
              label="title"
              v-model="selectedService"
              placeholder="Select Service"
            >
            </multiselect>
          </b-col>
          <b-col>
            <label class=""> Channel <span style="color: red">*</span> </label>
            <multiselect
              class="border-0"
              :options="chennelArray"
              label="name"
              v-model="selectedChannel"
              placeholder="Select Channel"
            >
            </multiselect>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label class="mt-2 ">Country <span style="color: red">*</span> </label>
            <multiselect
              class="border-0"
              :options="countryOpt"
              label="name"
              track-by="name"
              v-model="selectedCountry"
              placeholder="Select Country"
              @input="changeNetworkType"
              @remove="removeNetworkType"
            >
            </multiselect>
          </b-col>
          <b-col>
            <label class="mt-2 ">Network Type <span style="color: red">*</span> </label>
            <multiselect
              class="border-0"
              :options="networkTypeArray"
              label="name"
              track-by="id"
              v-model="selectedNetworkType"
              placeholder="Select Network Type"
            >
            </multiselect>
          </b-col>
        </b-row>
        <hr class="my-4" />
        <h6 class="heading-small text-muted mb-4">Urls information</h6>

        <div class="">
          <b-row>
            <b-col md="12">
              <label>Banner Found URL</label>
              <b-input
                type="text"
                label="Banner Found URL"
                placeholder="Banner Found Url"
                v-model="trxData.bannerFoundUrl"
              >
              </b-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
                <label class="mt-2">Ad Url <span style="color: red">*</span> </label>
            </b-col>
            <b-col>
                <b-input
                    type="text"
                    label=""
                    placeholder="Ad Url"
                    v-model="trxData.adUrl"
                >
                </b-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <label class="mt-2">Final URL <span style="color: red">*</span> </label>
              <b-input
                type="text"
                label="Final URL"
                placeholder="Final Url"
                v-model="trxData.finalUrl"
              >
              </b-input>
            </b-col>
          </b-row>
        </div>

        <b-row v-show="trx_id">
          <div class="">
          <hr class="my-4" />
          <h6 class="heading-small text-muted mb-4">Screenshot Upload</h6>
          <b-row>
            <b-col md="12" class="mt-3">
              <label>Banner Image</label>
              <b-row>
                <vue-dropzone
                  :id="trxData._id"
                  class="vue-dropzone"
                  ref="bannerDropzone"
                  :destroyDropzone="false"
                  @vdropzone-removed-file="onBannerFileRemoved"
                  @vdropzone-mounted="bannerDropzoneMounted"
                  :options="{
                    ...multidropzoneOptionsSingle,
                    url: bannerUploadUrl + `/${trxData._id}`,
                  }"
                ></vue-dropzone>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="mt-3">
              <label>Ad Image</label>
              <b-row>
                <vue-dropzone
                  :id="trxData._id"
                  class="vue-dropzone"
                  ref="adDropzone"
                  :destroyDropzone="false"
                  @vdropzone-removed-file="onAdFileRemoved"
                  @vdropzone-mounted="adDropzoneMounted"
                  :options="{
                    ...multidropzoneOptionsSingle,
                    url: adUploadUrl + `/${trxData._id}`,
                  }"
                ></vue-dropzone>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="mt-3">
              <label>Landing Image</label>
              <b-row>
                <vue-dropzone
                  style="color: red"
                  :id="trxData._id"
                  class="vue-dropzone"
                  ref="landingDropzone"
                  :destroyDropzone="false"
                  @vdropzone-added-file="onFileAdded"
                  @vdropzone-removed-file="onLandingFileRemoved($event, id)"
                  @vdropzone-mounted="landingDropzoneMounted"
                  :options="{
                    ...multidropzoneOptionsLanding,
                    url: landingUploadUrl + `/${trxData._id}`,
                  }"
                ></vue-dropzone>
              </b-row>
            </b-col>
          </b-row>

          <hr class="my-4" />
          <h6 class="heading-small text-muted mb-4">Network Logs</h6>
        </div>

        <div class="">
          <b-card no-body>
            <b-tabs pills card>
              <b-tab title="Hosts" active>
                <b-card-text>
                  <span v-if="hosts.length >= 0">
                    <li v-for="(item, key) in hosts" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteHost(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <span class="mt-2 text-secondary" style="cursor: pointer;">
                    <feather type="info" title="Example: xyz.com, abc.in"></feather>
                  </span>
                  <b-col class="col-md-9">
                    <b-form-input
                      type="text"
                      label="Hosts"
                      placeholder="Please enter comma separated hosts"
                      v-model="InputHost"
                      v-on:keyup.enter="AddHosts()"
                    ></b-form-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddHosts()"
                      >Add Host</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="URL Chain">
                <b-card-text>
                  <span v-if="urlChain.length >= 0">
                    <li v-for="(item, key) in urlChain" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteUrlChain(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <span class="mt-2 text-secondary" style="cursor: pointer;">
                    <feather type="info" title="Example: https://xyz.com, http://abc.in"></feather>
                  </span>
                  <b-col class="col-md-9">
                    <b-input
                      type="text"
                      label="URL Chain"
                      placeholder="Please enter comma separated urls"
                      v-model="InputUrlChain"
                      v-on:keyup.enter="AddUrlChain()"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddUrlChain()"
                      >Add URL</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Log">
                <b-card-text>
                  <span v-if="logs.length >= 0">
                    <li v-for="(item, key) in logs" :key="key">
                      {{ item }} -
                      <span
                        class="link_txt text-danger"
                        @click="deleteLogs(key)"
                        >Delete</span
                      >
                    </li>
                  </span>
                </b-card-text>
                <b-row>
                  <b-col class="col-md-10">
                    <b-input
                      type="text"
                      label="Logs"
                      placeholder="input logs to add"
                      v-model="InputLogs"
                      v-on:keyup.enter="AddLogs()"
                    >
                    </b-input>
                  </b-col>
                  <b-col>
                    <b-button variant="primary" @click="AddLogs()"
                      >Add Logs</b-button
                    >
                  </b-col>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
        <hr class="my-2" />
       
        <!-- Conditionally render "is Approved" field based on role -->
        <b-row v-if="userinfo.role !== 'tester'">
          <b-col>
            <label class="mt-2">is Approved ?</label>
            <div class="media-body icon-state switch-outline">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="isApproved"
                  @change="changeApproval(isApproved)"
                />
                <span
                  class="switch-state"
                  :class="isApproved ? 'bg-success' : 'bg-danger'"
                ></span>
              </label>
            </div>
          </b-col>
        </b-row>
        
        </b-row>
      </b-card>
     
      <template #modal-footer>
        <div>
          <b-button
            variant="primary"
            class="float-right mr-2"
            @click="changeTrxDetail()"
            :disabled="disable_submit"
          >
            {{ isEdit ? "Save" : "Save & Next" }}
          </b-button>
          <b-button class="float-right mr-2" @click="closeModal()">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <!-- Service Modal -->
    <b-modal
      v-model="add_service_modal"
      id="openSPDetail"
      title="Service Provider"
      class="theme-modal"
      @hidden="init()"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <serdetail
        :serviceModal="add_service_modal"
        @closeSdDetail="add_service_modal = false"
      ></serdetail>
    </b-modal>
    <!-- End Service provider Modal -->

    <!-- Service provider Modal -->
    <b-modal
      v-model="spModal"
      id="openSPDetail"
      title="Service Provider"
      class="theme-modal"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <spdetail :spModal="spModal" @closeSPDetail="spModal = false"></spdetail>
    </b-modal>

    <!-- Service Modal -->
    <b-modal
      v-model="smModal"
      id="openServiceMapper"
      title="Service Provider"
      class="theme-modal"
      size="xl"
      no-close-on-backdrop
      hide-footer
    >
      <service-mapper></service-mapper>
    </b-modal>
    <!-- End Service provider Modal -->
  </div>
</template>

<script>
import TrxServices from "../../../../services/TranscationService";
import ServiceInfoApi from "../../../../services/ServiceInfo";
import NetworkTypeService from "../../../../services/NetworkTypeService";
import Multiselect from "vue-multiselect";
import serdetail from "../serviceinfo/AddServiceInfo.vue";
import spdetail from "../serviceprovider/AddServiceProvider.vue";
import config from "../../../../services/globalLit";
import ServiceMapper from "../serviceMapping.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  components: {
    Multiselect,
    serdetail,
    spdetail,
    ServiceMapper,
    vueDropzone: vue2Dropzone,
  },
  beforeDestroy() {
    this.is_destroying = true;
  },
  destroyed() {
    this.is_destroying = false;
  },
  data() {
    return {
      landingImgLimit: 12,
      imagePreviewUrls: [],
      landingImages: [],
      img: "",
      spModal: false,
      smModal: false,
      add_service_modal: false,
      disable_submit: false,
      bannerImg: "",
      landingImg: "",
      landingImgs: [],
      imageOptions: [],
      imageValue: [],
      adImg: "",
      screenshot: {},
      selectedNetworkType: {},
      chennelArray: config.channelTypeArray,
      networkTypeArray: [],
      selectedChannel: {},
      selectedService: {},
      serviceList: [],
      trxData: {},
      trx_id: null,
      isApproved: false,
      isEdit: false,
      urlChain: "",
      hosts: [],
      logs: "",
      countryOpt: [],
      selectedCountry: [],
      InputHost: "",
      InputUrlChain: "",
      InputLogs: "",
      userinfo: {},
      multidropzoneOptionsLanding: {
        destroyDropzone: false,
        defaultHeaders: true,
        withCredentials: true,
        thumbnailWidth: 180,
        thumbnailHeight: 200,
        createImageThumbnails: true,
        dictRemoveFileConfirmation: 'Are you sure to delete this image?',
        maxFilesize: 2,
        maxFiles: 12,
        addRemoveLinks: true,
        acceptedFiles: "image/jpeg,image/png",
        dictRemoveFile: '<i class="icon-trash" style="color: #ff3f70;font-weight: bold;"></i>',
        dictDefaultMessage:
          "<i class='icon-cloud-up'></i><h6>Drop landing images here or click to upload.</h6>",
      },
      landingUploadUrl: config.uploadLandingImage,
      multidropzoneOptionsSingle: {
        destroyDropzone: false,
        defaultHeaders: true,
        withCredentials: true,
        thumbnailWidth: 180,
        thumbnailHeight: 200,
        createImageThumbnails: true,
        dictRemoveFileConfirmation: 'Are you sure to delete this image?',
        maxFilesize: 2,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: "image/jpeg,image/png",
        dictRemoveFile: '<i class="icon-trash" style="color: #ff3f70;font-weight: bold;"></i>',
        dictDefaultMessage:
          "<i class='icon-cloud-up'></i><h6>Drop image here or click to upload.</h6>",
          maxfilesexceeded: function(file) {
            this.removeFile(file);
            alert('You can only upload 1 file');
          }
      },
      bannerUploadUrl: config.uploadBannerImage,
      adUploadUrl: config.uploadAdImage,
    };
  },

  mounted() {

    this.userinfo = JSON.parse(window.localStorage.getItem("userinfo")) || {};
    this.init();

    this.$root.$on("trxdetail", async (data) => {

      this.imagePreviewUrls = [];
      this.landingImages = [];
      if (data) {
        this.trxData = data;
        this.isEdit = true;
        this.trx_id = data._id

        this.urlChain = data.urlChain.filter(
          (item) => item !== null && item !== ""
        );
        this.logs = data.logs.filter((item) => item !== null && item !== "");
        this.hosts = data.hosts.filter((item) => item !== null && item !== "");
        this.isApproved = data.isApproved;
        this.selectedService = this.serviceList.find((e) => data.sid == e._id);
        this.selectedChannel = config.channelTypeArray.find(
          (e) => data.channel == e.id
        );
        this.selectedNetworkType = this.networkTypeArray.find(
          (e) => data.netType == e.id
        );
        this.selectedCountry = this.countryOpt.find(
          (e) => data.country == e.id
        );
        this.bannerImg = config.publicgetBannerImages + `/${data._id}.png`;
        this.adImg = config.publicgetAddImages + `/${data._id}.png`;
        this.landingImgs = data.landingImgUrl;

        this.landingImages = data.landingImgUrl;
      
        this.landingDropzoneMounted();
        this.bannerDropzoneMounted();
        this.adDropzoneMounted();
      } else {
        this.isEdit = false;
        (this.bannerImg = ""),
          (this.landingImg = ""),
          (this.adImg = ""),
          (this.trxData = {});
        this.selectedService = [];
        this.selectedNetworkType = [];
        this.selectedChannel = [];
        this.selectedCountry = [];
        this.isApproved = false;
        this.hosts = [];
        this.urlChain = [];
        this.logs = [];
      }
    });
  },
  methods: {
    async removeNetworkType() {
      this.selectedCountry = [];
      await this.changeNetworkType();
    },
    async changeNetworkType() {
      console.log(this.selectedCountry);
      try {
        await this.getNetworkType(this.selectedCountry.id);
      } catch (error) {
        console.log("error in getting net type");
        console.log(error);
      }
    },
    async imageUrlToBase64(url) {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;
      });
    },


    async landingDropzoneMounted() {
      const dropzone = this.$refs.landingDropzone;

      if (dropzone && this.landingImages.length) {
        for (const url of this.landingImages) {
          const URLParams = new URLSearchParams(url.split("?")[1]);
          const index = URLParams.get("index");

          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], `index_${index}`, {
            type: "image/png",
          });
          dropzone.manuallyAddFile(file, url);
        }
      }
    },
    async bannerDropzoneMounted() {
      const dropzone = this.$refs.bannerDropzone;
      if (dropzone && this.bannerImg) { 
        try {
          const response = await fetch(this.bannerImg);
          if(response.status === 200) {
            const blob = await response.blob();
            const file = new File([blob], "banner_image.png", { type: blob.type }); 
            dropzone.manuallyAddFile(file, this.bannerImg);
          }
          
        } catch (error) {
          console.error("Error fetching or adding banner image to Dropzone:", error);
        }
      }
    },
    async adDropzoneMounted() {
      const dropzone = this.$refs.adDropzone;
      if (dropzone && this.adImg) {
        try {
          const response = await fetch(this.adImg);
          if(response.status === 200) {
            const blob = await response.blob();
            const file = new File([blob], "ad_image.png", { type: blob.type }); 
            dropzone.manuallyAddFile(file, this.adImg);
          }
          
        } catch (error) {
          console.error("Error fetching or adding ad image to Dropzone:", error);
        }
      }
    },
    onFileAdded(file) {
      this.landingImages.push(file);
    },
    async onLandingFileRemoved(e, trxId) {
      // this.landingImages = this.landingImages.filter((image) => image.name !== file.name);
      if (this.is_destroying) {
        return;
      }
      let payload = {
        trx_id: this.trx_id,
        index: e.name,
      };
      // console.log("payload", payload);
      try {
        await TrxServices.removeLaningImage(payload);
      } catch (e) {
        console.error("Error in deleting landing image");
        console.error(e);
      }
    },
    async onBannerFileRemoved() {
      if (this.is_destroying) {
        return;
      }
      let payload = {
        trx_id: this.trx_id
      };
      // console.log("payload", payload);
      try {
        await TrxServices.removeBannerImage(payload);
      } catch (e) {
        console.error("Error in deleting banner image");
        console.error(e);
      }
    },
    async onAdFileRemoved() {
      if (this.is_destroying) {
        return;
      }
      let payload = {
        trx_id: this.trx_id
      };
      // console.log("payload", payload);
      try {
        await TrxServices.removeAdImage(payload);
      } catch (e) {
        console.error("Error in deleting ad image");
        console.error(e);
      }
    },
    addImage() {
      this.landingImages.push(null);
    },
    removeImage(index) {
      this.landingImages.splice(index, 1);
      this.imagePreviewUrls.splice(index, 1);
    },
    previewImage(f, index) {
      const file = f[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imagePreviewUrls.splice(index, 1, reader.result);
      };
    },
    getImageLanding(id) {
      return config.getLandingImages + `/${id}`;
    },
    getImageBanner(id) {
      return config.getBannerImages + `/${id}`;
    },
    getImageAdd(id) {
      return config.getAddImages + `/${id}`;
    },
    async init() {
      await this.getNetworkType();
      await this.getCountry();
      let response = await ServiceInfoApi.get();
      if (response.result) {
        this.serviceList = response.data.reverse().filter((item) => item.status !== false);
      }
    },
    async getNetworkType(country) {
      let response = await NetworkTypeService.getAllNetworkType(country);
      let networkTypeArray = [];
      for (const [key, value] of Object.entries(response.data)) {
        networkTypeArray.push({ id: key, name: value });
      }
      this.networkTypeArray = networkTypeArray;
    },
    async getCountry() {
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [];
      response.data.forEach((element) => {
        this.countryOpt.push({ id: element.alpha_3, name: element.name });
      });
    },
    closeModal() {
      this.isEdit = false
      this.trx_id = null
      this.$root.$emit("trxlist", {});
      this.$bvModal.hide("openTrxDetail");
    },
    async changeTrxDetail() {
      if (this.validate()) {
        if (this.isEdit) {
          await this.editTrxData();
        } else {
          await this.saveBasicInfo();
        }
      }
    },
    async editTrxData() {
      this.disable_submit = true;
      let payload = {
        trxid: this.trx_id,
        changeBody: {
          netType: this.selectedNetworkType["id"],
          channel: this.selectedChannel["id"],
          country: this.selectedCountry["id"],
          isApproved: this.isApproved,
          banner: this.screenshot.banner,
          ad: this.screenshot.ad,
          
          finalUrl: this.trxData.finalUrl,
          bannerFoundUrl: this.trxData.bannerFoundUrl,
          adUrl: this.trxData.adUrl,

          hosts: this.hosts,
          urlChain: this.urlChain,
          logs: this.logs,
        },
      };

      if (this.selectedService) {
        payload.changeBody.sid = this.selectedService._id;
      }

      let response = await TrxServices.update(payload);
      let variant = response.result ? "success" : "error";
      this.$toasted.show(response.message, {
        theme: "outline",
        position: "bottom-center",
        type: variant,
        duration: 1000,
        onComplete: () => {
          this.$root.$emit("trxlist", {});
          this.$bvModal.hide("openTrxDetail");
          this.disable_submit = false;
          this.trx_id = null
        },
      });
    },
    async saveBasicInfo() {
      if (this.validate()) {
        this.disable_submit = true;
        this.trxData.sid = this.selectedService._id;
        (this.trxData.netType = this.selectedNetworkType["id"]),
        (this.trxData.channel = this.selectedChannel["id"]),
        this.trxData.country = this.selectedCountry["id"];

        let response = await TrxServices.addBasicInfo(this.trxData);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
          onComplete: () => {
            // this.$root.$emit("trxlist", {});
            // this.$bvModal.hide("openTrxDetail");
            this.disable_submit = false;
          },
        });
        if(response.result) {
          let data = response.data

          if(data) {
            this.trx_id = data._id
            let bannerUploadUrl = config.uploadBannerImage + `/${data._id}`;
            let adUploadUrl = config.uploadAdImage + `/${data._id}`;
            let landingUploadUrl = config.uploadLandingImage + `/${data._id}`;
            // this.multidropzoneOptionsBanner.url = bannerUploadUrl
            this.$refs.bannerDropzone.setOption('url', bannerUploadUrl);
            this.$refs.adDropzone.setOption('url', adUploadUrl);
            this.$refs.landingDropzone.setOption('url', landingUploadUrl);
         
            this.isEdit = true;
          }
          
        }

      }
    },
    bannerFileChange(f) {
      const file = f[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.bannerImg = reader.result;
        this.screenshot.banner = reader.result;
      };
    },
    adFileChange(f) {
      const file = f[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.adImg = reader.result;
        this.screenshot.ad = reader.result;
      };
    },
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = new Uint8Array(buffer);
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return binary;
    },
    landingFileChange(f) {
      this.landingImgs = [];
      this.screenshot.landingImgs = [];
      for (let i = 0; i < f.length; i++) {
        const file = f[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.landingImgs.push(reader.result);
          this.screenshot.landingImgs.push(reader.result);
        };
      }
    },
    openImageModal(data) {
      // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`);
      const popupWindow = window.open(
        "",
        "_blank",
        `width=${screen.width},height=${screen.height}`
      );
      popupWindow.document.write(
        `<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`
      );
      popupWindow.document.addEventListener("keydown", function (event) {
        if (event.key === "Escape" || event.keyCode === 27) {
          popupWindow.close();
        }
      });
    },
    async changeApproval(data) {
      if (data == false) {
        this.isApproved = true;
      } else {
        this.isApproved = false;
      }
    },
    AddHosts() {
      let urls = this.InputHost.split(",");
      urls.forEach((url) => {
        if (config.validateHost(url) == false) {
          this.$toasted.show("Please enter valid host", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          return;
        } else {
          this.hosts = [...this.InputHost.split(",")];
          this.InputHost = "";
        }
      });
    },
    deleteHost(key) {
      if (confirm("Are you sure to delete this host?")) {
        this.hosts.splice(key, 1);
      }
    },
    AddUrlChain() {
      let urls = this.InputUrlChain.split(",");
      urls.forEach((url) => {
        if (config.isValidUrl(url) == false) {
          this.$toasted.show("Please enter valid url", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          return;
        } else {
           this.urlChain = [...this.InputUrlChain.split(",")];
           this.InputUrlChain = "";
        }
      });
    },
    deleteUrlChain(key) {
      if (confirm("Are you sure to delete this url?")) {
        this.urlChain.splice(key, 1);
      }
    },
    AddLogs() {
      if (this.InputLogs != "") {
        this.logs.push(this.InputLogs);
        this.InputLogs = "";
      }else{
        this.$toasted.show("Please enter log", {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
          return;
      }
    },
    deleteLogs(key) {
      if (confirm("Are you sure to delete this log?")) {
        this.logs.splice(key, 1);
      }
    },
    validate() {
      const showToast = (message) => {
        this.$toasted.show(message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      };

      if (this.selectedService && this.selectedService.length == 0) {
        showToast("Please select service");
        return false;
      }
      if (this.selectedChannel && this.selectedChannel.length == 0) {
        showToast("Please select channel");
        return false;
      }
      if (this.selectedChannel && this.selectedCountry.length == 0) {
        showToast("Please select country");
        return false;
      }
      if (this.selectedChannel && this.selectedNetworkType.length == 0) {
        showToast("Please select network type");
        return false;
      }

      if(this.trxData.adUrl == '' || config.isValidUrl(this.trxData.adUrl) == false){
        showToast('Please enter valid ad url');
        return false;
      }

      if (
        this.trxData.finalUrl == null ||
        this.trxData.finalUrl == "" ||
        config.isValidUrl(this.trxData.finalUrl) == false
      ) {
        showToast("Please enter valid final url");
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.hidden_header {
  display: none;
}
div {
  margin: auto 0;
  width: 100%;
}
.img_custom_style {
  min-height: 200px;
  min-width: 180px;
  max-height: 250px;
  max-width: 250px;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 3px solid;
}



</style>
